import Dashboard3 from "./Pages/Dashboard3";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Dashboard3 />
    </>
  );
}
export default App;